<script lang="ts" setup>
import type { StatusColor } from '~/types'

const modelValue = defineModel<boolean | any[]>()

const props = withDefaults(
  defineProps<{
    inputValue?: string | number | boolean | null
    isDisableUserSelect?: boolean
    isNewDesign?: boolean
    isOrderTag?: boolean
    isStatus?: boolean
    labelText?: string | null
    name: string
    orderTagColor?: string | null
    statusColor?: StatusColor | null
  }>(),
  {
    inputValue: null,
    isDisableUserSelect: false,
    isNewDesign: false,
    isOrderTag: false,
    isStatus: false,
    labelText: null,
    orderTagColor: null,
    statusColor: null
  }
)

const emit = defineEmits<{
  (e: 'onCheckboxChanged', event: any): void
}>()
</script>

<template>
  <label class="checkbox-area flex cursor-pointer items-center" :class="{ 'disable-user-select': isDisableUserSelect }">
    <input
      v-model="modelValue"
      class="mr-2.5 cursor-pointer"
      :class="{ 'new-design': isNewDesign }"
      :name="name"
      type="checkbox"
      :value="inputValue"
      @change="emit('onCheckboxChanged', $event)"
    >

    <Status v-if="isStatus && statusColor" :color="statusColor" :title="labelText" />
    <OrderTag v-else-if="isOrderTag && orderTagColor" :color="orderTagColor" :title="labelText" />
    <span v-else-if="labelText" class="simple" v-html="labelText" />
  </label>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

.checkbox-area {
  color: variables.$main-gray-color;
  font-size: 16px;
  line-height: 20px;

  &.disable-user-select {
    user-select: none;
  }
}

input[type='checkbox'] {
  appearance: none;
  background: color.mix(variables.$main-gray-color, white, 10%);
  border: none;
  flex-shrink: 0;
  height: 16px;
  outline: none;
  position: relative;
  width: 16px;

  &:before {
    border-bottom: 2px solid variables.$main-green-color;
    border-right: 2px solid variables.$main-green-color;
    content: '';
    display: none;
    height: 7px;
    left: 6px;
    position: absolute;
    top: 3px;
    transform: rotate(45deg);
    width: 4px;
  }

  &:checked:before {
    display: block !important;
  }

  &.new-design {
    border-radius: 4px;

    &:before {
      border-bottom: 2px solid white;
      border-right: 2px solid white;
    }

    &:checked {
      background: variables.$main-green-color;
    }
  }
}
</style>
